<template>
	<b-modal centered size="sm" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-form-group class="mb-2" label="1. Resultado">
				<b-form-radio-group buttons v-model="result" name="result">
					<b-form-radio button-variant="outline-success btn-sm" size="sm" :value="1">
						{{ status == 3 ? "De alta" : "Vivo" }}
					</b-form-radio>
					<b-form-radio button-variant="outline-danger btn-sm" :value="2">
						{{ status == 3 ? "De baja" : "Muerto" }}
					</b-form-radio>
				</b-form-radio-group>
			</b-form-group>
			<b-form-group class="mb-2" label="2. Comentario">
				<b-form-textarea v-model="comment" no-resize />
			</b-form-group>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="send" :disabled="isLoading || result == null">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import nurseryService from "@/services/nursery/default.service"

export default {
	props: {
		show: Boolean,
		info: Object,
		status: {
			type: Number,
			default: 3,
		},
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: "",
		result: null,
		comment: null,
	}),
	computed: {
		isChick() {
			return !!this.info.chick
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.result = null
			this.comment = null
			this.$emit("closing")
		},
		async alive() {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se pasara el ${this.isChick ? "pollo" : "ejemplar"} ${
					this.isChick ? this.info.chick.correlative : this.info.specimen.alias || this.info.specimen.plate
				} a ${this.status == 3 ? "FINALIZADO" : "CANCELADO"}`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			if (this.isChick) {
				await nurseryService.changeChickStatus({
					nursing_id: this.info.id,
					status_id: this.status,
					is_dead: false,
					status_chick: 2,
					comment: this.comment,
				})
			} else {
				await nurseryService.changeSpecimenStatus({
					nursing_id: this.info.id,
					status_id: this.status,
					is_dead: false,
					comment: this.comment,
				})
			}
			this.isLoading = false
			this.handleHidden()
			this.$emit("refresh")
		},
		async dead() {
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se pasara el ${this.isChick ? "pollo" : "ejemplar"} ${
					this.isChick ? this.info.chick.correlative : this.info.specimen.alias || this.info.specimen.plate
				} a ${this.status == 3 ? "FINALIZADO" : "CANCELADO"}`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			if (this.isChick) {
				await nurseryService.changeChickStatus({
					nursing_id: this.info.id,
					status_id: this.status,
					is_dead: true,
					status_chick: 3,
					comment: this.comment,
				})
			} else {
				await nurseryService.changeSpecimenStatus({
					nursing_id: this.info.id,
					status_id: this.status,
					is_dead: true,
					comment: this.comment,
				})
			}
			this.isLoading = false
			this.handleHidden()
			this.$emit("refresh")
		},
		send() {
			if (this.result == 1) this.alive()
			else this.dead()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.title = `${this.status == 3 ? "Finalizar" : "Cancelar"} enfermeria del ${
					this.isChick ? "pollo" : "ejemplar"
				} ${this.isChick ? this.info.chick.correlative : this.info.specimen.alias || this.info.specimen.plate}`
			}
		},
	},
}
</script>
